import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'element' ];

  append(event) {
    this.fetchPartial(event, 'append');
  }

  prepend(event) {
    this.fetchPartial(event, 'prepend');
  }

  replace(event) {
    this.fetchPartial(event, 'replace');
  }

  update(event) {
    this.fetchPartial(event, 'update');
  }

  remove(event) {
    this.elementTarget.remove()
  }

  fetchPartial(event, action) {
    event.preventDefault();
    const target = event.currentTarget
    const path = target.tagName === 'A' ? target.href : target.dataset.contentLoaderPath

    fetch(path)
      .then((r) => r.text())
      .then((html) => {
          const fragment = document
            .createRange()
            .createContextualFragment(html);

          switch(action) {
            case 'append':
              this.elementTarget.append(fragment);
              break;
            case 'prepend':
              this.elementTarget.prepend(fragment);
              break;
            case 'replace':
              this.elementTarget.replaceWith(fragment);
              break;
            case 'update':
              this.elementTarget.innerHTML = '';
              this.elementTarget.append(fragment);
              break;
          }
      });
  }
}
