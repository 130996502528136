import LocalTime from "local-time"

LocalTime.config.i18n["de"] = {
  date: {
    dayNames: ["So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa."],
    abbrDayNames: ["So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa."],
    monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    abbrMonthNames: ["Jan", "Feb", "Mrz", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    yesterday: "gestern",
    today: "heute",
    tomorrow: "morgen",
    on: "{date}",
    formats: {
      default: "%e.%m.%Y",
      thisYear: "%e.%m.%Y"
    }
  },
  time: {
    am: "am",
    pm: "pm",
    singular: "einer {time}",
    singularAn: "einer {time}",
    elapsed: "vor {time}",
    second: "Sek.",
    seconds: "Sek.",
    minute: "Min.",
    minutes: "Min.",
    hour: "Std.",
    hours: "Std.",
    formats: {
      "default": "%H:%M"
    }
  },
  datetime: {
    at: "{date}, {time}",
    formats: {
      "default": "%B %e, %Y um %H:%M %Z"
    }
  }
}
