// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require.context('../images', true);

import Rails from '@rails/ujs';
Rails.start();

require("@rails/activestorage").start()
require("channels")

import 'bootstrap/dist/js/bootstrap'
import 'jquery-countdown'
import 'controllers' // Stimulus

import '../src/javascripts/juko'
import '../src/javascripts/copypaste'

import LocalTime from "local-time"
import '../src/javascripts/local_time_i18n'
LocalTime.start()

window.LocalTime = LocalTime
window.$ = $
window.Rails = Rails

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
