import consumer from "./consumer"

if ($("meta[name='current-user']").length > 0) {
  consumer.subscriptions.create("DuelChannel", {

    connected() {
    },

    disconnected() {
    },

    rejected() {
    },

    received(data) {
      // reload duel view if already on that page
      if (window.location.pathname == data.challenge.url) {
        window.location.assign(data.challenge.url)
      }
    }
  })
}
