import ClipboardJS from 'clipboard'

// Tooltip
function setTooltip(btn, message) {
  $(btn).tooltip('hide')
    .attr('data-original-title', message)
    .tooltip('show');
}
function hideTooltip(btn) {
  setTimeout(function() {
    $(btn).tooltip('hide');
  }, 1000);
}

$(document).ready(function() {
  $('[data-clipboard-target]').tooltip({
    trigger: 'click',
    placement: 'bottom'
  });
  // Clipboard
  var clipboard = new ClipboardJS('[data-clipboard-target]');
  clipboard.on('success', function(e) {
    setTooltip(e.trigger, 'Copied!');
    hideTooltip(e.trigger);
  });
  clipboard.on('error', function(e) {
    setTooltip(e.trigger, 'Failed!');
    hideTooltip(e.trigger);
  });
});
