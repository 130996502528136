import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["message"]

  onPostSuccess(event) {
    this.messageTarget.value = ""
    this.messageTarget.focus()
  }
}
