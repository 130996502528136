import consumer from "./consumer"

$(document).ready(function() {

  var chat_id, chat_box, chat_messages_url, scrollToBottom,
    scrollToBottomMobile, addNewMessageBubbleMobile, fetchAndReplaceAllChatMessages, messages_box,
    sidebar_right, sidebar_right_bubble;

  chat_id = $('#chat_id').val();

  let isMobile = (window.matchMedia("only screen and (max-width: 1199px)").matches
    || $("meta[name='mobile-app']").length > 0);

  if (chat_id) {
    chat_box = $('#chat_box_'+chat_id);
    messages_box = chat_box.find('.messages:first');
    sidebar_right = $('#sidebar-right');
    sidebar_right_bubble = $('#sidebar-right-chat-tab-bubble');

    chat_messages_url = chat_box.data('chat-messages-url');

    scrollToBottom = function() {
      if (chat_box.length > 0) {
        return chat_box.scrollTop(chat_box.prop("scrollHeight"));
      }
    };

    scrollToBottomMobile = function(condition = true) {
      if (isMobile && chat_box.length > 0 && sidebar_right.length > 0 && condition) {
        chat_box.scrollTop(chat_box.prop("scrollHeight"));
        return document.documentElement.scrollTop = chat_box.prop("scrollHeight");
      }
    };

    addNewMessageBubbleMobile = function() {
      if (isMobile && chat_box.length > 0 && sidebar_right.length > 0 && !sidebar_right.hasClass('active')) {
        document.documentElement.scrollTop = chat_box.prop("scrollHeight");
        sidebar_right_bubble.html('<span class="badge badge-dot badge-secondary"></span>');
        $('#sidebar-right-chat-tab').addClass('sidebar-toggler-pulse');
      }
    };
    
    fetchAndReplaceAllChatMessages = function() {
      fetch(chat_messages_url)
        .then((r) => r.text())
        .then((html) => {
          const fragment = document
            .createRange()
            .createContextualFragment(html)

            messages_box.html(html);
            scrollToBottom();
            scrollToBottomMobile(sidebar_right.hasClass('active'));
        });
    };
  
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      localStorage.setItem('activeTab', $(e.target).attr('href'));
      localStorage.setItem('activeTabHref', location.href);
    });

    var activeTab = localStorage.getItem('activeTab');
    var activeTabHref = localStorage.getItem('activeTabHref');

    if (activeTab && activeTabHref && activeTabHref == location.href) {
      $('.nav-tabs a[href="' + activeTab + '"]').tab('show');
      scrollToBottomMobile(sidebar_right.hasClass('active'));
    }

    $('#sidebar-right-chat-tab').on('shown.bs.tab', function (e) {
      scrollToBottomMobile();
    });

    $('#sidebar-right-chat-tab').on('click', function(e) {
      sidebar_right_bubble.html('');
      $('#sidebar-right-chat-tab').removeClass('sidebar-toggler-pulse');
    });

    scrollToBottom();

    this.chatChannel = consumer.subscriptions.create({
      channel: 'ChatChannel',
      chat_id: chat_id
    }, {
      connected() {
        // fetchAndReplaceAllChatMessages();
        scrollToBottom();
        scrollToBottomMobile(sidebar_right.hasClass('active'));
      },
      received(data) {
        // make sure no messages get appended multiple times
        if (!$("#message_"+data.message.id).length) {
          messages_box.append(data.message.html);
          scrollToBottom();
          scrollToBottomMobile(sidebar_right.hasClass('active'));
          addNewMessageBubbleMobile();
          this.perform('read_message', {
            message_id: data.message.id
          });
        }
      },
      rejected() {
        messages_box.append('<div class="mt-2 alert alert-danger alert-fill">Chat Error</div>');
        scrollToBottom();
      }
    });
  } else {
    if (this.chatChannel) {
      consumer.subscriptions.remove(this.chatChannel);
    }
  }
});
