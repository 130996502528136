import consumer from "./consumer"

if ($("meta[name='current-user']").length > 0) {
  consumer.subscriptions.create("PresenceChannel", {
    initialized() {
      this.focus = this.focus.bind(this)
    },

    connected() {
      this.install_focus()
      this.perform("online")
    },

    disconnected() {
      this.uninstall_focus()
      this.perform("offline")
    },

    rejected() {
      this.uninstall_focus()
      this.perform("offline")
    },

    focus() {
      if (document.visibilityState == "visible" && document.hasFocus()) {
        this.perform("online")
      } else {
        this.perform("away")
      }
    },

    install_focus() {
      window.addEventListener("focus", this.focus)
      window.addEventListener("blur", () => setTimeout(this.focus, 10000))
      document.addEventListener("DOMContentLoaded", this.focus)
      document.addEventListener("visibilitychange", () => setTimeout(this.focus, 10000))
    },

    uninstall_focus() {
      window.removeEventListener("focus", this.timer)
      window.removeEventListener("blur", () => setTimeout(this.focus, 10000))
      document.removeEventListener("DOMContentLoaded", this.timer)
      document.removeEventListener("visibilitychange", () => setTimeout(this.focus, 10000))
    },

    received(data) {
      $('.PresenceUser_'+data.user_id)
        .removeClass('avatar-status-offline')
        .removeClass('avatar-status-away')
        .removeClass('avatar-status-online')
      $('.PresenceUser_'+data.user_id).addClass('avatar-status-'+data.presence_status)
    }
  })
}
