import consumer from "./consumer"

$(document).ready(function() {

  var arenaID = $('#arena_id').val();

  if (arenaID) {
    
    this.arenaPresenceChannel = consumer.subscriptions.create({
      channel: 'ArenaPresenceChannel',
      arena_id: arenaID
    }, {

      connected() {
        if (typeof startTimer === "function") { startTimer() }
        this.perform("enter_arena", { arena_id: arenaID })
      },

      disconnected() {
        // this.perform("leave_arena")
      },

      rejected() {
        // this.perform("leave_arena")
      },

      received(data) {
        if (data.presence_status == 'entered') {
          // $('#updateArenaOpponents').trigger('click')

        } else if (data.presence_status == 'left') {
          $('#arenaTileUser_'+data.user_id+' .arena-card-overlay').addClass('active');

        } else if (data.presence_status == 'changed_profile') {
          if (data.arena_search_active == false) {
            $('#arenaTileUser_'+data.user_id+' .arena-card-overlay').addClass('active');
          } else {
            fetch(data.arena_search_setting_url)
              .then((r) => r.text())
              .then((html) => {
                const fragment = document
                  .createRange()
                  .createContextualFragment(html)

                  $('#arenaTileUser_'+data.user_id).fadeOut("slow", function(){
                    $('#arenaTileUser_'+data.user_id).replaceWith(fragment)
                    $('#arenaTileUser_'+data.user_id).fadeIn("slow");
                  })
              });
          }
        }
      }
    })

  } else {
    if (this.arenaPresenceChannel) {
      consumer.subscriptions.remove(this.arenaPresenceChannel);
    }
  }
});
