import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content"];

  show(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (!$('#remoteModalWrapper').is(':visible')) {
      $('#remoteModalWrapper').modal('show')
    }
    fetch(this.data.get("url"))
      .then((r) => r.text())
      .then((html) => {
        const fragment = document
          .createRange()
          .createContextualFragment(html)

        $('#remoteModalWrapper').html(fragment)
      });
  }

}
