import consumer from "./consumer"

if ($("meta[name='current-user']").length > 0) {
  consumer.subscriptions.create("NotificationChannel", {

    connected() {
    },

    disconnected() {
    },

    rejected() {
    },

    received(data) {
      let old_notification = $('.notification[data-notification-parent-id='+data.notification.parent_id+']').first();
      if (old_notification.length) {
        old_notification.replaceWith(data.notification.html);
      } else {
        $('#notifications').prepend(data.notification.html);
      }
      
      if (data.notification.force_reload == true) {
        location.reload();
      }
    }
  })
}
