import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    if (!this.isPreview) {
      this.element.classList.remove('invisible')
      this.element.classList.add('animated')
    }
  }

  disconnect() {
    this.element.classList.add('invisible')
    this.element.classList.remove('animated')
  }

  get isPreview() {
    return document.documentElement.hasAttribute('data-turbolinks-preview')
  }
}
