$(document).ready(function() {

  /*#####################################
  TOOLTIPS
  ####################################*/
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  });

  /*#####################################
  THEME SWITCH
  ####################################*/
  $('#switchTheme').click(function(){
    if($(this).is(":checked")) {
      $('body').toggleClass("theme-dark");
      $('body').removeClass("theme-light");
    } else {
      $('body').toggleClass("theme-light");
      $('body').removeClass("theme-dark");
    }
    Rails.ajax({
      url: '/?dark_mode='+$(this).is(":checked"),
      type: "get"
    })
  });

  /*#####################################
  OFFCANVAS
  ####################################*/
  $(function () {
    $('[data-toggle="offcanvas-left"]').on('click', function () {
      $('.offcanvas-collapse-left').toggleClass('show');
      $('body').toggleClass("offcanvas-active offcanvas-active-left");
      $(".offcanvas-overlay").toggleClass("show");
    });

    $('[data-toggle="offcanvas-right"]').on('click', function () {
      $('.offcanvas-collapse-right').toggleClass('show');
      $('body').toggleClass("offcanvas-active offcanvas-active-right");
      $(".offcanvas-overlay").toggleClass("show");
    });

    $(".btn-close, .offcanvas-overlay").on('click', function () {
      $(".offcanvas-overlay").removeClass("show");
      $(".offcanvas-collapse").removeClass("show");
      $("body").removeClass("offcanvas-active offcanvas-active-right offcanvas-active-left");
    });
  });


  /*#####################################
  MULTILEVEL DROPDOWNS
  ####################################*/
  $('.dropdown-menu a.dropdown-toggle').on('click', function() {
    if (!$(this).next().hasClass('show')) {
      $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    }
    var $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass('show');


    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function() {
      $('.dropdown-submenu .show').removeClass("show");
    });

    $(this).parents('.navbar-collapse.show').on('click', function() {
      $('.dropdown-submenu .show').removeClass("show");
    });

    return false;
  });


  /*#####################################
  Smooth Scrolling
  ####################################*/
  $(".smoothscroll").on('click', function(e) {
    // prevent default anchor click behavior
    e.preventDefault();
    // store hash
    var hash = this.hash;
    // animate
    $('html, body').animate({
      scrollTop: $(hash).offset().top
    }, 800, function(){
      // when done, add hash to url
      // (default click behaviour)
      window.location.hash = hash;
    });
  });

});

  /*#####################################
  TYPEWRITER
  ####################################*/

  var TxtType = function(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
  };

  TxtType.prototype.tick = function() {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="typewrite-text typewrite-blinking">'+this.txt+'</span>';

    var that = this;
    var delta = 200 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }

    setTimeout(function() {
      that.tick();
    }, delta);
  };

$(document).ready(function() {
  var elements = document.getElementsByClassName('typewrite');
  for (var i=0; i<elements.length; i++) {
    var toRotate = elements[i].getAttribute('data-type');
    var period = elements[i].getAttribute('data-period');
    if (toRotate) {
      new TxtType(elements[i], JSON.parse(toRotate), period);
    }
  }
});
