import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["results", "query"]

  requestSearch(){
    const resultsArea = this.resultsTarget
    if (this.queryTarget.value.length > 0) {
      resultsArea.classList.add("show")
      fetch('/search/autocomplete?query='+ this.queryTarget.value)
      .then(response=> {
        return response.text();
      })
      .then(html=> {
        resultsArea.innerHTML = html;
      });
    } else {
      resultsArea.classList.remove("show")
    }
  }
}
