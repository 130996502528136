import consumer from "./consumer"

if ($("meta[name='current-user']").length > 0) {
  consumer.subscriptions.create("MessageChannel", {

    connected() {
    },

    disconnected() {
    },

    rejected() {
    },

    received(data) {
      fetch(data.messages_counter_url)
        .then((r) => r.text())
        .then((html) => {
          const fragment = document
            .createRange()
            .createContextualFragment(html)

            $('.unread_messages_counter').replaceWith(fragment)
        });
    }
  })
}
